import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";

import ApiService from "common/api/Api";
import { propertyActions } from "services/Property/PropertySlice"
import { StorageService } from "common/storage/storage-service"


export const Files = () => {
    const [progress, setProgress] = useState(0);
    const [defaultList, setDefaultLIst] = useState(undefined)
    
    const { contract } = useSelector((state) => state.contract);
    const { property, status, user, media } = useSelector(
        (state) => state.property
    );
    const dispatch = useDispatch();
    const uploadFIle = async (options) => {
        const { file } = options;
        const fmData = new FormData();
        fmData.append("media", file);
        fmData.append("group", "contract_file");
        fmData.append("parent_id", parseInt(contract?.id));
console.log(fmData);
        let res = await ApiService.uploadImage(options, fmData, setProgress);
        if (res.status == "200") {
            dispatch(propertyActions.getMedia({ entityId: property?.contract_active[0]?.id, entityName: 'contract' }))
        }

    };

    const PreviewFile = async (file) => {
        // Your process logic. Here we just mock to the same file
        const token = await StorageService.getItem('token')
        return fetch(file.url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'BearerToken': token
            }
        })
            .then(res => res.blob())
            .then(blob => {
                var file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            });

    };

    useEffect(() => {
        if (media) {
            let listFile = []
            media?.map(file => {
                listFile.push({
                    uid: file.id,
                    name: file.filename,
                    status: 'done',
                    response: 'Server Error 500', // custom error message to show
                    url: `https://api.yampi.co/media/private/${file.id}${file.filename} `,
                })
            })
            setDefaultLIst(listFile)
        }
    }, [media])

    return (
        <>
            <Upload customRequest={uploadFIle} accept=".pdf" fileList={defaultList} showUploadList={
                {
                    showDownloadIcon: false,
                    showRemoveIcon: false
                }
            } onPreview={PreviewFile} >
                <Button icon={<UploadOutlined />}>Subir archivo de soporte s</Button>
            </Upload>
        </>
    )
}