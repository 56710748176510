import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descriptions, Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col, Select, Form } from "antd";
import { useHistory } from "react-router-dom";
import ApiService from "../../../../common/api/Api"
import { shoppingActions } from "services/Shopping/ShoppingSlice";
import { propertyActions } from "services/Property/PropertySlice";
import { dispersionActions } from "services/Dispersion/DispersionSlice";
import { columnsDispersionData, columnsDispersionHistory } from "../constans";
import TableRow from "scenes/Private/StateAccount/pdf_gen/TableRow";
import { formmatterCurrency } from "common/utilities";
import ReactExport from "react-export-excel";


export const ModalDownloadBankFormat = () => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { dispersions, status, pagination, filterData, reload } = useSelector(state => state.dispersion)

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [selectedBank, setSelectedBank] = useState(null);

  const handleBankChange = (value) => {
    setSelectedBank(value);
  };
  const dataProccesorExcel = (col) => {
    return col.map(row => {

      let bankCode = 0;
      let code_piv = 0;
      let userBankData = row.metadata[0]?.data_user_bank;
      let userData = row.metadata[0]?.data_user;
      if (row.userId == "1") {
        if (userBankData != null) {
          code_piv = userBankData.bankAccountDistribution?.bank.id;
        }

      } else {
        let adminData = row.metadata[0]?.data_user_bank;
        if (adminData != null) {
          code_piv = adminData.bank.id;
        }
      }

      switch (code_piv) {
        case 1://Bank of America
          bankCode = '';
          break;
        case 2://Banco Agrario
          bankCode = 40;
          break;
        case 3://Banco AV Villas
          bankCode = 52;
          break;
        case 4://Banco Caja Social
          bankCode = 32;
          break;
        case 5://Banco de Occidente
          bankCode = 23;
          break;
        case 6://Banco Popular
          bankCode = 2;
          break;
        case 7://Bancolombia
          bankCode = 7;
          break;
        case 8://BBVA Colombia
          bankCode = 13;
          break;
        case 9://Banco de Bogotá
          bankCode = 1;
          break;
        case 10://Citi Colombia
          bankCode = 9;
          break;
        case 11://Colpatria
          bankCode = 19;
          break;
        case 12://Davivienda
          bankCode = 51;
          break;
        case 13://GNB Sudameris
          bankCode = 12;
          break;
        case 20://efectivo
          bankCode = 20;
          break;
        case 21://otro
          bankCode = 21;
          break;
      }

      let bankAccType = 0;
      let bankAccTypePiv = userBankData?.bankAccountTypeId;
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          bankAccTypePiv = adminData.bankAccountDistribution.typeProdServ;
        }
      }
      switch (bankAccTypePiv) {
        case 1:
        case 3:
          bankAccType = 'CA';
          break;
        case 2:
        case 4:
          bankAccType = 'CC';
          break;
      }

      let docidCode = 0;
      switch (userData?.docid[0]?.docidTypeId) {
        case 1:
          docidCode = 5;
          break;
        case 2:
          docidCode = 'Licencia de conduccion';
          break;
        case 3:
          docidCode = 1;
          break;
        case 4:
          docidCode = 2;
          break;
        case 5:
          docidCode = 3;
          break;
      }

      let code = (userData?.docid[0]?.code);
      let name = (userData?.profiles[0]?.name);
      let lastname = (userData?.profiles[0]?.lastname);
      let number = (userBankData?.number);
      let valuePay = (row.valuePay);
      let email = (userData?.email);
      let propertyName = row.metadata[0]?.prop_name;

      ///si es de tipo administracion
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          code = (adminData.bankAccountDistribution.numId);
          name = (adminData.bankAccountDistribution.name);
          lastname = (adminData.bankAccountDistribution.lastname);
          number = (adminData.bankAccountDistribution.numProdServ);
          valuePay = (row.valuePay);
          email = (adminData.bankAccountDistribution.email);
          propertyName = row.metadata[0]?.prop_name;

        }
      }

      if (docidCode == 0 || bankAccType == 0 || bankCode == 0 ||
        code == undefined || name == undefined ||
        lastname == undefined || number == undefined ||
        valuePay == undefined || email == undefined
      ) {
        return null
      } else {
        return {
          typeDoc: docidCode,
          docNum: code,
          name: name,
          lastName: lastname,
          codeBank: bankCode,
          typeProduct: bankAccType,
          numProd: number,
          valueTotal: valuePay,
          reference: "ARRIENDO",
          email: email,
          description: propertyName
        };
      }
    }).filter(row => row !== null);;

  }

  const dataProccesorExcelBc = (col) => {
    const headerRows = [
      {
        typeDoc: 'NIT PAGADOR',
        docNum: 'TIPO DE PAGO',
        name: 'APLICACIÓN',
        typeTrans: 'SECUENCIA DE ENVÍO',
        codeBank: 'NRO CUENTA A DEBITAR',
        numProd: 'TIPO DE CUENTA A DEBITAR',
        email: 'DESCRIPCIÓN DEL PAGO',
        docAuthorized: '',
        reference: '',
        phone: '',
        value: '',
        dateApplay: ''
      },
      {
        typeDoc: '123456',
        docNum: '220',
        name: 'I',
        typeTrans: 'A1',
        codeBank: '1111111111',
        numProd: 'S',
        email: 'Ejemplo1',
        docAuthorized: '',
        reference: '',
        phone: '',
        value: '',
        dateApplay: ''
      },
      {
        typeDoc: 'Tipo Documento Beneficiario',
        docNum: 'Nit Beneficiario',
        name: 'Nombre Beneficiario',
        typeTrans: 'Tipo Transaccion',
        codeBank: 'Código Banco',
        numProd: 'No Cuenta Beneficiario',
        email: 'Email',
        docAuthorized: 'Documento Autorizado',
        reference: 'Referencia',
        phone: 'Celular Beneficiario',
        value: 'ValorTransaccion',
        dateApplay: 'Fecha de aplicación',

      }
    ];



    const processedData = col.map(row => {
      let bankCode = 0;
      let code_piv = 0;
      let userBankData = row.metadata[0]?.data_user_bank;
      let userData = row.metadata[0]?.data_user;
      if (row.userId == "1") {
        if (userBankData != null) {
          code_piv = userBankData.bankAccountDistribution?.bank.id;
        }

      } else {
        let adminData = row.metadata[0]?.data_user_bank;
        if (adminData != null) {
          code_piv = adminData.bank.id;
        }
      }

      switch (code_piv) {
        case 2://Banco Agrario
          bankCode = 1040;
          break;
        case 3://Banco AV Villas
          bankCode = 1052;
          break;
        case 4://Banco Caja Social
          bankCode = 1032;
          break;
        case 5://Banco de Occidente
          bankCode = 1023;
          break;
        case 6://Banco Popular
          bankCode = 1002;
          break;
        case 7://Bancolombia
          bankCode = 1007;
          break;
        case 8://BBVA Colombia
          bankCode = 1013;
          break;
        case 9://Banco de Bogotá
          bankCode = 1001;
          break;
        case 10://Citi Colombia
          bankCode = 1009;
          break;
        case 11://Colpatria
          bankCode = 1019;
          break;
        case 12://Davivienda
          bankCode = 1051;
          break;
        case 13://GNB Sudameris
          bankCode = 1012;
          break;
        case 20://efectivo
          bankCode = 1020;
          break;
        case 21://otro
          bankCode = 0;
          break;
      }

      let bankAccType = 0;
      let bankAccTypePiv = userBankData?.bankAccountTypeId;
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          bankAccTypePiv = adminData.bankAccountDistribution.typeProdServ;
        }
      }
      switch (bankAccTypePiv) {
        case 1:
        case 3:
          bankAccType = 'CA';
          break;
        case 2:
        case 4:
          bankAccType = 'CC';
          break;
      }

      let docidCode = 0;
      switch (userData?.docid[0]?.docidTypeId) {
        case 1:
          docidCode = 5;
          break;
        case 2:
          docidCode = 'Licencia de conduccion';
          break;
        case 3:
          docidCode = 1;
          break;
        case 4:
          docidCode = 2;
          break;
        case 5:
          docidCode = 3;
          break;
      }

      let code = (userData?.docid[0]?.code);
      let name = (userData?.profiles[0]?.name);
      let lastname = (userData?.profiles[0]?.lastname);
      let number = (userBankData?.number);
      let valuePay = (row.valuePay);
      let email = (userData?.email);
      let propertyName = row.metadata[0]?.prop_name;

      ///si es de tipo administracion
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          code = (adminData.bankAccountDistribution.numId);
          name = (adminData.bankAccountDistribution.name);
          lastname = (adminData.bankAccountDistribution.lastname);
          number = (adminData.bankAccountDistribution.numProdServ);
          valuePay = (row.valuePay);
          email = (adminData.bankAccountDistribution.email);
          propertyName = row.metadata[0]?.prop_name;

        }
      }

      if (docidCode == 0 || bankAccType == 0 || bankCode == 0 ||
        code == undefined || name == undefined ||
        lastname == undefined || number == undefined ||
        valuePay == undefined || email == undefined
      ) {
        return null
      } else {
        return {
          typeDoc: docidCode,
          docNum: code,
          name: name + ' ' + lastname,
          typeTrans: 0,
          codeBank: bankCode,
          numProd: number,
          email: email,
          docAuthorized: '',
          reference: "ARRIENDO " + ' ' + propertyName,
          phone: '',
          value: valuePay,
          dateApplay: ''
        };
      }
    }).filter(row => row !== null);;

    return [...headerRows, ...processedData];

  }

  // Función para generar ExcelSheet para Bancolombia
  const renderBancolombiaExcelSheet = (data) => (
    <ExcelSheet data={data} name="Estado de cuenta">
      <ExcelColumn value={(col) => col.typeDoc || ''} />
      <ExcelColumn value={(col) => col.docNum || ''} />
      <ExcelColumn value={(col) => col.name + ' ' + col.lastName || ''} />
      <ExcelColumn value={(col) => col.typeTrans || ''} />
      <ExcelColumn value={(col) => col.codeBank || ''} />
      <ExcelColumn value={(col) => col.numProd || ''} />
      <ExcelColumn value={(col) => col.email || ''} />
      <ExcelColumn value={(col) => col.docAuthorized || ''} />
      <ExcelColumn value={(col) => col.reference || ''} />
      <ExcelColumn value={(col) => col.phone || ''} />
      <ExcelColumn value={(col) => col.value || ''} />
      <ExcelColumn value={(col) => col.dateApplay || ''} />
    </ExcelSheet>
  );

  // Función para generar ExcelSheet para Davivienda
  const renderDaviviendaExcelSheet = (data) => (
    <ExcelSheet data={data} name="Estado de cuenta">
      <ExcelColumn label="Tipo de identificación" value={(col) => col.typeDoc} />
      <ExcelColumn label="Número de identificación" value={(col) => col.docNum} />
      <ExcelColumn label="Nombre" value={(col) => col.name} />
      <ExcelColumn label="Apellido" value={(col) => col.lastName} />
      <ExcelColumn label="Código del banco" value={(col) => col.codeBank} />
      <ExcelColumn label="Tipo de Producto" value={(col) => col.typeProduct} />
      <ExcelColumn label="Número del Producto" value={(col) => col.numProd} />
      <ExcelColumn label="Valor total pago" value={(col) => col.valueTotal} />
      <ExcelColumn label="Referencia" value={(col) => col.reference} />
      <ExcelColumn label="Correo Electrónico" value={(col) => col.email} />
      <ExcelColumn label="Descripción" value={(col) => col.description} />
    </ExcelSheet>
  );

  return (
    <Row>
      {dispersions.length ? (
        <>
          <Row justify="end" style={{ marginTop: '0px' }}>
            <Col>
              <label>El archivo se generara con los registros que esten listos para su procesamiento en las plataformas bancarias.
              </label>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: '0px' }}>

            <Col>
              <>
                Seleccione un formato :
              </>
              <Select
                style={{ width: 200, margin: '5px' }}
                placeholder="Seleccione un banco"
                onChange={handleBankChange}
              >
                <Option value="bancolombia">Bancolombia</Option>
                <Option value="davivienda">Davivienda</Option>
              </Select>
            </Col>

            <Col>
              {selectedBank && (
                <ExcelFile
                  element={
                    <Button
                      style={{
                        borderRadius: '5px',
                        borderColor: '#d82067',
                        padding: '5px 20px',
                        height: 'auto',
                        fontSize: 'medium',
                        marginLeft: '10px',
                      }}
                      type="primary"
                    >
                      Descargar
                    </Button>
                  }
                >
                  {selectedBank === 'bancolombia'
                    ? renderBancolombiaExcelSheet(dataProccesorExcelBc(dispersions))
                    : renderDaviviendaExcelSheet(dataProccesorExcel(dispersions))}
                </ExcelFile>
              )}
            </Col>
          </Row>
        </>
      ) : null}
    </Row>
  );
};