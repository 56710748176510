import React, { useState } from 'react'

import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getColumnSearchProps } from 'common/utilities/getColumnSearchProps'
import { formmatterCurrency } from "common/utilities";
import { ShowButton } from '../components/ShowButton/ShowButton'
import { shoppingActions } from 'services/Shopping/ShoppingSlice'
import { FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons'

import moment from 'moment'
import 'moment/locale/es'
import PDF from './pdf_gen/PDF';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { forEach } from 'lodash';
import ImgCrop from 'antd-img-crop';
import { Button, Row, Upload } from 'antd';
import ApiService from "common/api/Api";

export const columns = (isModalVisible, setIsModalVisible, stateData, setStateData, userinfo, setUserInfo, isModalBreakdownVisible, setIsModalBreakdownVisible) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { pagination } = useSelector(state => state.shopping)
    const { page, limit } = pagination
    return [
        {
            title: 'Propiedad ',
            dataIndex: '',
            key: 'names_reg',
            render: (data) => {
                return <span>  {data.names_reg.prop_name}</span>

            }
        },
        {
            title: 'Propietario',
            dataIndex: '',
            key: 'names_reg',
            render: (data) => {
                return <span>  {data.names_reg.owner_name}</span>

            }
        },
        {
            title: 'Inquilino',
            dataIndex: '',
            key: 'names_reg',
            render: (data) => {
                return <span>  {data.names_reg.renter_name}</span>

            }
        },
        {
            title: 'Valor contrato',
            dataIndex: '',
            key: 'dis_reg',
            render: (data) => {
                let label = "";
                let data_reg = data.dis_reg;
                if (data_reg.length >= 1) {
                    label = formmatterCurrency(data.dis_reg[0].dis_reg.valueTotal);
                }
                return <span>  {label}  </span>

            }
        },
        {
            title: 'Tipo Tarifa',
            dataIndex: '',
            key: 'dis_reg',
            render: (data) => {
                let total_percent = 0;
                let total_nom = 0;
                let data_reg = data.dis_reg;
                if (data_reg.length >= 1) {
                    (data.dis_reg[0].dis_reg.dispersionContext.type == 0) ? total_percent += data.dis_reg[0].dis_reg.dispersionContext.commission : total_nom += data.dis_reg[0].dis_reg.dispersionContext.commission;
                    (data.dis_reg[0].dis_reg.dispersionContext.typeInmo == 0) ? total_percent += data.dis_reg[0].dis_reg.dispersionContext.commissionInmo : total_nom += data.dis_reg[0].dis_reg.dispersionContext.commissionInmo;
                    (data.dis_reg[0].dis_reg.dispersionContext.typeSecure == 0) ? total_percent += data.dis_reg[0].dis_reg.dispersionContext.commissionSecure : total_nom += data.dis_reg[0].dis_reg.dispersionContext.commissionSecure;
                }
                let label = "Porcentaje";
                if (total_nom != 0) {
                    label = "Fijo";
                }
                return <span><div>{label}</div></span>

            }
        },
        {
            title: 'Tarifa',
            dataIndex: '',
            key: 'dis_reg',
            render: (data) => {
                let total_percent = 0;
                let total_nom = 0;
                let data_reg = data.dis_reg;
                if (data_reg.length >= 1) {
                    //(data.dis_reg[0].dis_reg.dispersionContext.type == 0) ? total_percent += data.dis_reg[0].dis_reg.dispersionContext.commission : total_nom += data.dis_reg[0].dis_reg.dispersionContext.commission;
                    (data.dis_reg[0].dis_reg.dispersionContext.typeInmo == 0) ? total_percent += data.dis_reg[0].dis_reg.dispersionContext.commissionInmo : total_nom += data.dis_reg[0].dis_reg.dispersionContext.commissionInmo;
                    //(data.dis_reg[0].dis_reg.dispersionContext.typeSecure == 0) ? total_percent += data.dis_reg[0].dis_reg.dispersionContext.commissionSecure : total_nom += data.dis_reg[0].dis_reg.dispersionContext.commissionSecure;
                }
                let percent_form = total_percent * 100;
                let label = (percent_form) + "%";
                if (total_nom != 0) {
                    label = formmatterCurrency(total_nom);
                }
                return <span><div>{label}</div></span>

            }
        },
        {
            title: 'Valor',
            dataIndex: '',
            key: 'dis_reg',
            render: (data) => {
                let total_inmo = 0;
                let total_yampi = 0;
                let total_secure = 0;
                let total = 0;
                let total_percent = 0;
                let total_nom = 0;
                let data_reg = data.dis_reg;
                if (data_reg.length >= 1) {
                    total_inmo = data.dis_reg[0].dis_reg.valueInmo;
                    total_yampi = data.dis_reg[0].dis_reg.valueCom;
                    total_secure = data.dis_reg[0].dis_reg.valueSafeSecure;
                    total = total_inmo + total_yampi + total_secure;
                    total_percent = 0;
                    total_nom = 0;
                    //(data.dis_reg[0].dis_reg.dispersionContext.type == 0) ? total_percent += total_yampi : total_nom += total_yampi;
                    (data.dis_reg[0].dis_reg.dispersionContext.typeInmo == 0) ? total_percent += total_inmo : total_nom += total_inmo;
                    //(data.dis_reg[0].dis_reg.dispersionContext.typeSecure == 0) ? total_percent += total_secure : total_nom += total_secure;
                }
                let label = formmatterCurrency(total_percent);
                if (total_nom != 0) {
                    label = formmatterCurrency(total_nom);
                }
                return <span><div>{label}</div></span>
            }
        },
        {
            title: 'Administracion',
            dataIndex: '',
            key: 'admin',
            render: (data) => {
                return <span>  {formmatterCurrency(data.admin)}</span>

            }
        },
        {
            title: 'Descuentos',
            dataIndex: '',
            key: 'discounts',
            render: (data) => {
                return <span>  {formmatterCurrency(data.discounts.total_discount)}</span>

            }
        },
        {
            title: 'Observaciones',
            dataIndex: '',
            key: '',
            render: (data) => {
                let labels = [];
                let adminData = data.dis_reg;
                adminData.forEach(function (element, index) {
                    console.log(element.dis_reg.metadata[0].type_dis);
                    switch (element.dis_reg.metadata[0].type_dis) {
                        case 0:
                            labels.push("* Pago a Propietario realizado");
                            break;
                        case 1:
                            labels.push("* Pago a inmobiliaria realizado");
                            break;
                        case 2:
                            labels.push("* Pago a administracion realizado");
                            break;
                        case 4:
                            labels.push("* Pago a Yampi realizado");
                            break;
                        default:
                            break;
                    }
                    if (index !== adminData.length - 1) {
                        labels.push(<br />);
                    }
                });
                return <span>{labels}</span>;
            }
        },
        //{
        //    title: 'Total a pagar',
        //    dataIndex: '',
        //    key: 'dis_reg',
        //    render: (data) => {
        //        return <span>  {formmatterCurrency(data.dis_reg[0].dis_reg.valuePay)}</span>
        //
        //    }
        //},
        {
            title: 'Acciones',
            key: 'action',
            dataIndex: '',
            width: '10%',
            // <ShowButton onClick={() => { setIsModalVisible(true); setStateData([data]) }} />
            render: (data) => {

                let data_reg = data.dis_reg;
                if (data_reg.length == 0) {
                    return <>
                        <br />
                    </>
                } else {
                    return <>
                        <br />
                        <ShowButton onClick={() => { setIsModalBreakdownVisible(true); setStateData([data.dis_reg]) }} />
                    </>
                }

            }
        }
    ]
}
import ReactExport from "react-export-excel";
export const columnsBreackdown = (isModalVisible, setIsModalVisible, stateData, setStateData, userinfo, setUserInfo, id, setId, fileList, setFileList) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { pagination } = useSelector(state => state.shopping)
    const { page, limit } = pagination

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const uploadImage = async (options) => {
        const { file } = options;
        const fmData = new FormData();

        fmData.append("media", file);
        fmData.append("group", "pay_support_dis");
        fmData.append("parent_id", id);

        ApiService.uploadImage(options, fmData, 100);
    };

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const typeTarifa = (item) => {
        let total_inmo = item.dis_reg.valueInmo;
        let total_yampi = item.dis_reg.valueCom;
        let total_secure = item.dis_reg.valueSafeSecure;
        let total = total_inmo + total_yampi + total_secure;
        let total_percent = 0;
        let total_nom = 0;

        (item.dis_reg.dispersionContext.typeInmo == 0) ? total_percent += total_inmo : total_nom += total_inmo;

        let label = "Porcentual";
        if (total_nom != 0) {
            label = "Fijo";
        }
        return (label);
    };
    const totalPercent1 = (item) => {
        let total_yampi = item.dis_reg.dispersionContext.commissionInmo;

        let total_percent = 0;
        let total_nom = 0;
        (item.dis_reg.dispersionContext.typeInmo == 0) ? total_percent += total_yampi : total_nom += total_yampi;
        let percent_form = (total_percent * 100);
        let label = percent_form + "%";
        if (total_nom != 0) {
            label = "$" + formmatterCurrency(total_nom);
        }
        return (label);
    };
    const totalPercent2 = (item) => {
        let total_inmo = item.dis_reg.valueInmo;
        let total_percent = 0;
        let total_nom = 0;
        (item.dis_reg.dispersionContext.typeInmo == 0) ? total_percent += total_inmo : total_nom += total_inmo;
        let label = " ";
        if (total_percent != 0) {
            label = "-" + formmatterCurrency(total_percent);
        }
        if (total_nom != 0) {
            label = "-" + formmatterCurrency(total_nom);
        }

        return (
            label
        );
    };
    const labeldiscounts = (item) => {
        let label = "$0";
        if (item != 0) {
            label = "-" + foformmatterCurrency(item);
        }
        return (label);
    };
    const adminlabel = (item) => {
        let label = "$0";
        if (item != 0) {
            label = "-" + formmatterCurrency(item);
        }
        return (label
        );
    };
    const totalPayment = (item) => {
        let total = 0;
        total = item.dis_reg.valuePay;
        return Math.round(total);
    }
    const date_dispersed = (item) => {
        let date = item.dis_reg.dispersedAt;
        let today = new Date(date);
        let formattedDate = today.toISOString().split('T')[0];
        return formattedDate;
    }

    //
    const typeTarifaA = (item) => {
        let total_inmo = item.dis_reg.valueInmo;
        let total_yampi = item.dis_reg.valueCom;
        let total_secure = item.dis_reg.valueSafeSecure;
        let total = total_inmo + total_yampi + total_secure;
        let total_percent = 0;
        let total_nom = 0;
        (item.dis_reg.dispersionContext.type == 0) ? total_percent += total_yampi : total_nom += total_yampi;
        let label = "Porcentual";
        if (total_nom != 0) {
            label = "Fijo";
        }
        return (label);
    };

    const totalPercent1A = (item) => {
        console.log(item);
        let total_inmo = item.dis_reg.dispersionContext.commission;
        let total_percent = 0;
        let total_nom = 0;
        (item.dis_reg.dispersionContext.type == 0) ? total_percent += total_inmo : total_nom += total_inmo;
        let percent_form = (total_percent * 100);

        let label = percent_form + "%";
        if (total_nom != 0) {
            label = "$" + formmatterCurrency(total_nom);
        }

        return (label);
    };
    const totalPercent2A = (item) => {
        let total_inmo = item.dis_reg.valueInmo;
        let total_yampi = item.dis_reg.valueCom;
        let total_secure = item.dis_reg.valueSafeSecure;
        let total = total_inmo + total_yampi + total_secure;

        let total_percent = 0;
        let total_nom = 0;
        (item.dis_reg.dispersionContext.type == 0) ? total_percent += total_yampi : total_nom += total_yampi;
        //(item.dis_reg.dispersionContext.typeInmo == 0) ? total_percent += total_inmo : total_nom += total_inmo;
        //(item.dis_reg.dispersionContext.typeSecure == 0) ? total_percent += total_secure : total_nom += total_secure;
        let label = " ";
        if (total_percent != 0) {
            label = "-" + formmatterCurrency(total_percent);
        }
        if (total_nom != 0) {
            label = "-" + formmatterCurrency(total_nom);
        }

        return (label);
    };


    const dataProccesorExcelBc = (col) => {
        console.log(col);
        let adminData = col.dis_reg.metadata[0].type_dis;
        const headerRows = [
            {
                col1: col.dis_reg.context,
                col2: '',
                col3: col.dis_reg.context,
            },
            {
                col1: '',
                col2: '',
                col3: 'Informe estado de cuenta',
            },
            {
                col1: '',
                col2: col.names_reg.owner_name,
                col3: '',
            }
        ];
        let processedData = [];
        if (adminData == 0) {//"Pagado a Propietario"
            processedData = [
                {
                    col1: 'Item',
                    col2: 'Descripcion',
                    col3: 'Valor',

                },
                {
                    col1: 'Arriendo',
                    col2: '',
                    col3: formmatterCurrency(col.dis_reg.valueTotal),

                },
                {
                    col1: 'Administracion',
                    col2: '',
                    col3: adminlabel(col.admin),

                },
                {
                    col1: 'Honorarios',
                    col2: typeTarifa(col) + ' ' + totalPercent1(col),
                    col3: totalPercent2(col),

                },
                {
                    col1: 'Descuentos',
                    col2: (col.discounts.info == "") ? "_" : col.stateData.discounts.info,
                    col3: labeldiscounts(col.discounts.total_discount),

                },
                {
                    col1: '',
                    col2: 'Pagado a destinatario',
                    col3: formmatterCurrency(totalPayment(col)),

                },
                {
                    col1: ' ',
                    col2: 'Fecha de pago',
                    col3: date_dispersed(col),

                },

            ];
        } else {
            processedData = [
                {
                    col1: 'Item',
                    col2: 'Descripcion',
                    col3: 'Valor',

                },
                {
                    col1: 'Honorarios Inmobiliaria',
                    col2: '',
                    col3: formmatterCurrency(col.dis_reg.valueTotal),

                },
                {
                    col1: 'Honorarios YAMPI',
                    col2: typeTarifaA(col) + ' ' + totalPercent1A(col),
                    col3: totalPercent2A(col),

                },
                {
                    col1: 'Descuentos',
                    col2: (col.discounts.info == "") ? "_" : col.stateData.discounts.info,
                    col3: labeldiscounts(col.discounts.total_discount),

                },
                {
                    col1: '',
                    col2: 'Pagado a destinatario',
                    col3: formmatterCurrency(totalPayment(col)),

                },
                {
                    col1: ' ',
                    col2: 'Fecha de pago',
                    col3: date_dispersed(col),

                },

            ];
        }


        return [...headerRows, ...processedData];

    }


    return [
        {
            title: 'Destinatario ',
            dataIndex: '',
            key: 'names_reg',
            render: (data) => {
                let metadata = data.dis_reg.metadata[0];
                let label = "";
                let adminData = data.dis_reg.metadata[0].type_dis;
                let name_user = "";
                let lastname_user = "";
                switch (adminData) {
                    case 0:
                        name_user = data.dis_reg.user?.profiles[0].name;
                        lastname_user = data.dis_reg.user?.profiles[0].lastname;
                        label = name_user + " " + lastname_user;
                        break;
                    case 1:
                        name_user = metadata.data_user?.profiles[0].name;
                        lastname_user = metadata.data_user?.profiles[0].lastname;
                        label = name_user + " " + lastname_user;
                        break;
                    case 2:
                        let name = metadata.admin_data?.bankAccountDistribution.name;
                        let lastname = metadata.admin_data?.bankAccountDistribution.lastname;
                        label = name + " " + lastname;
                        break;

                }
                return <span> {label} </span>
            }
        },
        {
            title: 'Cuenta',
            dataIndex: '',
            key: '',
            render: (data) => {
                let metadata = data.dis_reg.metadata[0];
                let label = "";
                let adminData = data.dis_reg.metadata[0].type_dis;
                switch (adminData) {
                    case 0:
                    case 1:

                        label = data.dis_reg.bankAccount?.number;
                        break;
                    case 2:

                        label = metadata.admin_data?.bankAccountDistribution.numProdServ;
                        break;

                }
                return <span> {label} </span>

            }
        },
        {
            title: 'Banco',
            dataIndex: '',
            key: '',
            render: (data) => {
                let metadata = data.dis_reg.metadata[0];
                let label = "";
                let adminData = data.dis_reg.metadata[0].type_dis;
                switch (adminData) {
                    case 0:
                    case 1:
                        label = data.dis_reg.bankAccount?.bank.name;
                        break;
                    case 2:

                        label = metadata.admin_data?.bankAccountDistribution.bank.name;
                        break;

                }
                return <span> {label} </span>

            }
        },
        {
            title: 'Descripcion',
            dataIndex: '',
            key: '',
            render: (data) => {
                let label = "";
                let adminData = data.dis_reg.metadata[0].type_dis;
                switch (adminData) {
                    case 0:
                        label = "Pagado a Propietario";
                        break;
                    case 1:
                        label = "Pagado a inmobiliaria";
                        break;
                    case 2:
                        label = "Pagado a administracion";
                        break;

                }
                return <span> {label} </span>

            }
        },
        {
            title: 'Total Pagado',
            dataIndex: '',
            key: 'dis_reg',
            render: (data) => {
                return <span>  {formmatterCurrency(data.dis_reg.valuePay)}</span>

            }
        },
        {
            title: 'Fecha de transaccion',
            dataIndex: '',
            key: 'dis_reg',
            render: (data) => {
                let date = data.dis_reg.dispersedAt;
                let today = new Date(date);
                let formattedDate = today.toISOString().split('T')[0];
                return <span>  {formattedDate}</span>

            }
        },
        /** {
             title: 'Adjuntos',
             key: '',
             dataIndex: '',
             width: '10%',
             // <ShowButton onClick={() => { setIsModalVisible(true); setStateData([data]) }} />
             render: (data) => {
                 let files = data.media;
                 let labels = [];
                 files.forEach(function (element, index) {
                     labels.push(
                         <Button
                             type='primary'
                             onClick={() =>
                                 ApiService.getFile(`/media/private_p/${element.id}${element.filename.slice(0, 1) === '/' ? '' : '/'}${element.filename}`,
                                     {
                                         name: `${element.filename.slice(0, 1)}`
                                     })
                             } style={{ padding: "2px" }} className='aLink'>
                            {`${element.filename}`}
                         </Button>
                     );
                     if (index !== files.length - 1) {
                         labels.push(<br />);
                     }
                 });
                 return <span>{labels}</span>;
             }
         },*/
        {
            title: '',
            key: 'action',
            dataIndex: '',
            width: '10%',
            // <ShowButton onClick={() => { setIsModalVisible(true); setStateData([data]) }} />
            render: (data) => {

                let label = "";
                let adminData = data.dis_reg.metadata[0].type_dis;
                let files = data.media;
                switch (adminData) {
                    case 0:
                    case 1:
                        label = <>
                            <Button
                                style={{
                                    borderRadius: '50%', // Para que el botón sea circular 
                                    padding: '10px', // Ajusta el padding según el tamaño que desees
                                    height: 'auto',
                                    width: 'auto', // Permite que el tamaño del botón se adapte al contenido
                                    display: 'flex', // Centra el ícono
                                    alignItems: 'center', // Alinea verticalmente el ícono
                                    justifyContent: 'center', // Alinea horizontalmente el ícono
                                    fontSize: '24px', // Aumenta el tamaño del ícono si es necesario
                                    marginBottom: 20
                                }}
                                icon={<FilePdfOutlined style={{ fontSize: '48px' }} />}
                                onClick={() => { setIsModalVisible(true); setStateData([data]) }}
                            ></Button>
                        </>;
                        break;

                    case 20:
                        label = <>
                            {setId(data.dis_reg.id)}
                            <ImgCrop rotate>
                                <Upload
                                    accept="image/*"
                                    listType="picture-card"
                                    customRequest={uploadImage}
                                >
                                    {fileList.length < 5 && "+ Upload"}
                                </Upload>
                            </ImgCrop>
                        </>;
                        break;
                }

                label = <div style={{ display: 'flex', gap: '20px' }}>
                    {label}
                    <>
                        <ExcelFile element={
                            <Button
                                style={{
                                    borderRadius: '50%', // Para que el botón sea circular 
                                    padding: '10px', // Ajusta el padding según el tamaño que desees
                                    height: 'auto',
                                    width: 'auto', // Permite que el tamaño del botón se adapte al contenido
                                    display: 'flex', // Centra el ícono
                                    alignItems: 'center', // Alinea verticalmente el ícono
                                    justifyContent: 'center', // Alinea horizontalmente el ícono
                                    fontSize: '24px', // Aumenta el tamaño del ícono si es necesario

                                }}
                                icon={<FileExcelOutlined style={{ fontSize: '48px' }} />}
                            ></Button>}>
                            <ExcelSheet data={dataProccesorExcelBc(data)} name="Estado de cuenta">
                                <ExcelColumn value={(col) => col.col1 || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
                                <ExcelColumn value={(col) => col.col2 || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
                                <ExcelColumn value={(col) => col.col3 + ' '} ExcelCell style={{ alignment: { wrapText: true } }} />
                            </ExcelSheet>
                        </ExcelFile>
                    </>
                </div>;
                return <span> {label} </span>


            }
        }
    ]
}

export const columnsSend = (isModalVisible, setIsModalVisible, stateData, setStateData, userinfo, setUserInfo, selectedRows, setSelectedRows) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { pagination } = useSelector(state => state.shopping)
    const { page, limit } = pagination

    // Función para manejar la selección de filas
    const handleRowSelect = (row) => {
        let element = row.dis_reg.id;
        const index = selectedRows.findIndex(selectedRow => selectedRow.dis_reg.id === element);
        // Si la fila ya está seleccionada, la quitamos del estado
        if (index !== -1) {
            const newSelectedRows = [...selectedRows];
            newSelectedRows.splice(index, 1);
            setSelectedRows(newSelectedRows);
        } else { // Si la fila no está seleccionada, la agregamos al estado
            setSelectedRows([...selectedRows, row]);
        }
    };

    return [
        {
            title: 'Propiedad ',
            dataIndex: '',
            key: 'names_reg',
            render: (data) => {
                return <span>  {data.names_reg.prop_name}</span>

            }
        },
        {
            title: 'Propietario',
            dataIndex: '',
            key: 'names_reg',
            render: (data) => {
                //se obtienen los destinatarios
                let labels = [];
                let dis_reg = data.dis_reg;
                dis_reg.forEach(function (element, index) {
                    switch (element.dis_reg.metadata[0].type_dis) {
                        case 0:
                            labels.push(<span>  {element.names_reg.owner_name}</span>);
                            break;
                        case 1:
                            labels.push(<span>  {element.dis_reg.context} </span>);
                            break;
                        case 2:
                            labels.push(<span>  adminer</span>);
                            break;
                        default:
                            break;
                    }
                    if (index !== dis_reg.length - 1) {
                        labels.push(<br />);
                    }
                });

                return <span>  {labels}</span>

            }
        },
        {
            title: 'Email',
            dataIndex: '',
            key: 'names_reg',
            render: (data) => {
                let labels = [];
                let dis_reg = data.dis_reg;
                dis_reg.forEach(function (element, index) {
                    switch (element.dis_reg.metadata[0].type_dis) {
                        case 0:
                        case 1:
                            labels.push(<span>  {element.dis_reg.user.email} </span>);
                            break;
                        case 2:
                            let adminData = data.metadata[0].admin_data;
                            labels.push(<span>  {adminData.bankAccountDistribution.lastname}</span>);
                            break;
                        default:
                            break;
                    }
                    if (index !== dis_reg.length - 1) {
                        labels.push(<br />);
                    }
                });
                return <span>  {labels}</span>
            }
        },
        {
            title: 'Tipo de estado',
            dataIndex: '',
            key: 'names_reg',
            render: (data) => {
                let labels = [];
                let dis_reg = data.dis_reg;
                dis_reg.forEach(function (element, index) {
                    switch (element.dis_reg.metadata[0].type_dis) {
                        case 0:
                            labels.push(<span>  pago propietario </span>);
                            break;
                        case 1:
                            labels.push(<span> pago inmobiliaira </span>);
                            break;
                        case 2:
                            labels.push(<span>  pago administracion </span>);
                            break;
                        default:
                            break;
                    }
                    if (index !== dis_reg.length - 1) {
                        labels.push(<br />);
                    }
                });
                return <span>  {labels}</span>
            }
        },
        {
            title: 'Seleccion',
            key: 'action',
            dataIndex: '',
            width: '10%',
            render: (data) => {
                //  <ShowButton onClick={() => { setIsModalVisible(true); setStateData([data]) }} />
                let labels = [];
                let dis_reg = data.dis_reg;
                dis_reg.forEach(function (element, index) {
                    labels.push(<Row>
                        <input
                           style={{  marginBottom: '30px' }}
                            type="checkbox"
                            checked={selectedRows.some(selectedRow => selectedRow.dis_reg.id === element.dis_reg.id)}
                            onChange={() => handleRowSelect(element)}
                        />
                    </Row>);
                });
                return <div  > {labels}</div>

            }
        }
    ]
}

export const columnsDispersionHistory = () => {
    return [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Concepto',
            dataIndex: 'concept',
            key: 'concept',
        },
        {
            title: 'Creado el',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
    ]
}

export const columnsDispersionData = () => {
    return [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Valor de arriendo recaudado',
            dataIndex: 'valueTotal',
            key: 'valueTotal',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fee yampi',
            dataIndex: 'valueCom',
            key: 'valueCom',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fee inmobiliaria',
            dataIndex: 'valueInmo',
            key: 'valueInmo',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fee aseguradora',
            dataIndex: 'valueSafeSecure',
            key: 'valueSafeSecure',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Valor a pagar/pagado',
            dataIndex: 'valuePay',
            key: 'valuePay',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fecha creacion',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Pagado a',
            dataIndex: 'user',
            key: 'user',
            render: (value) => (
                <span>{value.profiles[0].name + " " + value.profiles[0].lastname}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'user',
            render: (value) => (
                <span>{value.email}</span>
            )
        },
        {
            title: 'Cuenta bancaria',
            dataIndex: 'bankAccount',
            key: 'bankAccount',
            render: (value) => (
                <span>{value?.number + ' ' + value?.bank?.name}</span>
            )
        },
        {
            title: 'Fees usados',
            dataIndex: 'dispersionContext',
            key: 'dispersionContext',
            render: (value) => (
                <span>{
                    "Fee Yampi = " + value?.commission + ' ' +
                    "Fee inmobiliaria  = " + value?.commissionInmo + ' ' +
                    "Fee aseguradora  = " + value?.commissionSecure

                }</span>
            )
        },
    ]
}
