
import { useEffect, useState } from 'react';
import { Checkbox, Button, Card, Col, Modal, Row, Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarOutlined } from '@ant-design/icons'
import { Filter } from './components/Filter';
import { columns, tablePagination } from './components/constans';
import { companyActions } from "services/Company/CompanySlice";
import { ModalChangeDataRenew } from './components/ModalChangeDataRenew';
export const ContractRenew = () => {

  const dispatch = useDispatch();
  const [cartsProperty, setCartsProperty] = useState([]);
  const [isModalEditContract, setIsModalEditContract] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { contractsIpc, status, companys, pagination, filterIpc, isViewRenewUpdate } = useSelector((state) => state.company);
  const [regenerateData, setRegenerateData] = useState(null);
  const [closeContractData, setCloseContractData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsM, setSelectedRowsM] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllM, setSelectAllM] = useState(false);

  const currentColumn = columns(regenerateData, setRegenerateData, setCloseContractData, selectedRows, setSelectedRows, selectedRowsM, setSelectedRowsM);
  const { page, limit, total } = pagination;


  // Función para seleccionar todas las filas
  const handleSelectAll = () => {
    if (contractsIpc.length >= 1) {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        let valid_reg = [];
        contractsIpc.forEach(element => {
          valid_reg.push(element)
        });
        setSelectedRows(valid_reg); // Asigna todas las filas como seleccionadas
      }
      setSelectAll(!selectAll); // Cambia el estado de selección de todos los checkboxes
    }
  };

  const handleApproveAll = async () => {
    // Puedes usar la fecha ingresada en el campo de texto aquí
    let answer = window.confirm("Desea confirmar que esta(s) configuraciones de aumento IPC son correctas?");
    if (answer) {
      const promises = selectedRows.map(async element => {
        console.log(element);
        let values = {
          register_id: parseInt(element.id),
          is_processed: 1,
          is_renew: true
        }
        dispatch(companyActions.setUpdateRenewRegister({ update: values, page, limit, filter: filterIpc }));
      });
    }
  }

  // Función para seleccionar todas las filas para envio de mensaje
  const handleSelectAllM = () => {
    if (contractsIpc.length >= 1) {
      if (selectAllM) {
        setSelectedRowsM([]);
      } else {
        let valid_reg = [];
        contractsIpc.forEach(element => {
          valid_reg.push(element)
        });
        setSelectedRowsM(valid_reg); // Asigna todas las filas como seleccionadas
      }
      setSelectAllM(!selectAllM); // Cambia el estado de selección de todos los checkboxes
    }
  };

  const handlePagination = (page, limit) => {
    dispatch(companyActions.getRenewContractList({ page, limit, filter: filterIpc })) 
  }

  useEffect(async () => {
    if (regenerateData != null) {
      let answer = window.confirm("Desea aplicar esta configuracion para la renovacion del contrato");
      if (answer) {
        let values = {
          register_id: parseInt(regenerateData),
          is_processed: 1,
          is_renew: true
        }
        dispatch(companyActions.setUpdateRenewRegister({ update: values, page, limit, filter: filterIpc }));
      } else {
        setRegenerateData(null);
      }
    }
  }, [regenerateData])

  useEffect(async () => {
    if (closeContractData != null) {
      let answer = window.confirm("Desea Cerrar el contrato en la proxima fecha de incio?");
      if (answer) {
        let values = {
          register_id: parseInt(closeContractData),
          is_renew: false,
          is_processed: 1
        }
        dispatch(companyActions.setUpdateRenewRegister({ update: values, page, limit, filter: filterIpc }));
      } else {
        setCloseContractData(null);
      }
    }
  }, [closeContractData])



  const tablePagination = {
    onChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total
  }

  const handleChange = (e) => {
    // Verifica si el checkbox está marcado 
      handleSelectAll(); // Llama a la función de selección
      handleSelectAllM(); // Llama a la segunda función
     
  };

  return <>
    <Filter key="filter" />
    <div style={{ padding: 10 }}>
      <div style={{ marginBottom: 20 }}>
        {/* Primera sección de botones */}
        <>
          <Checkbox onChange={handleChange} style={{ marginRight: 10 }}>
            Seleccionar Todo
          </Checkbox>

          <Button
            type="primary"
            onClick={() => handleApproveAll()}
            size="small"
            style={{ width: 90, marginRight: 10 }}
          >
            Aprobar
          </Button>
          <Button
            type="primary"
            onClick={() => handleSelectAllM()}
            size="small"
            style={{ width: 90, marginRight: 10 }}
          >
            Enviar
          </Button>
        </>
      </div>
    </div>
    <Spin spinning={status === "loading"}>
      <Table
        title={() =>
          <>
            <Row justify="space-between">
              <Col flex={1} > <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Lista de contratos</span></Col>
              <Col >
                <Button
                  key="1"
                  type="primary"
                  onClick={() => ''}
                >
                </Button>
              </Col>
              <Col>

              </Col>
            </Row>
          </>
        }
        columns={currentColumn}
        dataSource={contractsIpc}
        pagination={tablePagination}
        scroll={{ x: 400 }}
      />
    </Spin>
    {isViewRenewUpdate &&
      <ModalChangeDataRenew
        visible={isViewRenewUpdate}
      />
    }
  </>
}