import React, { useState, useEffect } from 'react'
import { Layout, Menu } from 'antd'
import styled from 'styled-components';

import logo from '../../../../assets/logo-purple.png'
import { TeamOutlined, HomeOutlined, DollarOutlined, SafetyCertificateOutlined, ToolOutlined, MailOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const { Sider: AntdSider } = Layout
const { SubMenu } = Menu


const menu = [
  {
    key: "user",
    title: "Usuarios",
    icon: <TeamOutlined />,
    route: '/user'
  },
  {
    key: "property",
    title: "Propiedades",
    icon: <HomeOutlined />,
    route: '/property'
  },
  {
    key: "shopping",
    title: "Recaudos",
    icon: <DollarOutlined />,
    route: '/shopping'
  },
  {
    key: "data",
    title: "Informes",
    icon: <DollarOutlined />,
    submenu: [
      {
        key: "dispersion",
        title: "Dispersiones",
        icon: <DollarOutlined />,
        route: '/dispersion'
      },
      {
        key: "stateAccount",
        title: "Estados de cuenta",
        icon: <DollarOutlined />,
        route: '/stateAccount'
      },
      {
        key: "payment-send",
        title: "Días de recaudo",
        icon: <MailOutlined />,
        route: '/payment-send'
      },
      /*{
        key: "contract_renew",
        title: "Renovaciones",
        icon: <DollarOutlined />,
        route: '/contract_renew'
      },*/
    ]
  }
]

const menuAdmin = [
  {
    key: "specialist",
    title: "Especialistas",
    icon: <TeamOutlined />,
    route: '/specialist'
  },
  {
    key: "user",
    title: "Usuarios",
    icon: <TeamOutlined />,
    route: '/user'
  },
  {
    key: "property",
    title: "Propiedades",
    icon: <HomeOutlined />,
    route: '/property'
  },
  {
    key: "shopping",
    title: "Recaudos",
    icon: <DollarOutlined />,
    route: '/shopping'
  },
  {
    key: "data",
    title: "Informes",
    icon: <DollarOutlined />,
    submenu: [
      {
        key: "dispersion",
        title: "Dispersiones",
        icon: <DollarOutlined />,
        route: '/dispersion'
      },
      {
        key: "stateAccount",
        title: "Estados de cuenta",
        icon: <DollarOutlined />,
        route: '/stateAccount'
      },
      /*{
        key: "contract_renew",
        title: "Renovaciones",
        icon: <DollarOutlined />,
        route: '/contract_renew'
      },*/
      {
        key: "payment-send",
        title: "Días de recaudo",
        icon: <MailOutlined />,
        route: '/payment-send'
      },
  /*     {
        key: "novedades",
        title: "Novedades",
        icon: <MailOutlined />,
        route: '/novedades'
      }, */
      {
        key: "conciliation",
        title: "Conciliación",
        icon: <MailOutlined />,
        route: '/conciliation'
      },
      {
        key: "SysLog",
        title: "Log sistema",
        icon: <MailOutlined />,
        route: '/sysblueprint'
      },
      {
        key: "billing",
        title: "Facturacion",
        icon: <MailOutlined />,
        route: '/billing'
      } 
    ]
  },
  {
    key: "permission",
    title: "Permisos",
    icon: <SafetyCertificateOutlined />,
    route: '/permission'
  },
  {
    key: "tikects",
    title: "Mantenimientos",
    icon: <ToolOutlined />,
    route: '/tickets'
  },
  {
    key: "files",
    title: "Procesos Masivos",
    icon: <ToolOutlined />,
    route: '/process_files'
  },
  {
    key: "company",
    title: "Compañias",
    icon: <ToolOutlined />,
    route: '/company'
  }
]

const SiderCustom = styled(AntdSider)`
  background: #f0f2f5;
  margin: 20px 0 0 20px;
  padding: 13px 20px;
  .ant-menu{
    background: none;  
  }
  .ant-menu-item .ant-menu-item-icon + span, .sider .ant-menu-submenu-title .ant-menu-item-icon + span, .sider .ant-menu-item .anticon + span, .sider .ant-menu-submenu-title .anticon + span{
    color: #141414;
    font-size: 15px;
    padding: 0;
  }
  @media (min-width: 1200px){
    margin: 20px 0 0 8px;
    flex: 0 0 250px!important;
    max-width: 250px!important;
    width: 250px!important;
  }
  @media (max-width: 680px) {

  }

`;



export const Sider = () => {

  const history = useHistory()
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [openKeys, setOpenKeys] = useState(["data"]); // Submenu "Informes" abierto por defecto
  const rolesAdmin = ["sysadmin", "admin"];
  const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))

  const getClassName = (path) => (
    pathname.includes(path) || (pathname === '/' && path.includes('/user'))
      ? 'menu-item-selected'
      : 'menu-item'
  )

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  
  const handleOpenChange = (keys) => {
    setOpenKeys(keys); // Maneja la apertura/cierre de submenús
  };

  return (
    <SiderCustom breakpoint="lg" collapsedWidth="0" className="sider">
      <div className="logo">
        <img style={{ height: '60px' }} src={logo} alt="logo" />
      </div>
      <Menu
        mode="inline"
        openKeys={openKeys} // Controla qué submenús están abiertos
        onOpenChange={handleOpenChange} // Maneja cambios de apertura
      >
        {!isAdmin && menu.map(item => (
          item.submenu ? (
            <SubMenu key={item.key} icon={item.icon} title={item.title}>
              {item.submenu.map(subitem => (
                <Menu.Item
                  key={subitem.key}
                  className={getClassName(subitem.route)}
                  onClick={() => history.push(subitem.route)}
                >
                  {subitem.title}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              className={getClassName(item.route)}
              onClick={() => history.push(item.route)}
            >
              {item.title}
            </Menu.Item>
          )
        ))}
        {isAdmin && menuAdmin.map(item => (
          item.submenu ? (
            <SubMenu key={item.key} icon={item.icon} title={item.title}>
              {item.submenu.map(subitem => (
                <Menu.Item
                  key={subitem.key}
                  className={getClassName(subitem.route)}
                  onClick={() => history.push(subitem.route)}
                >
                  {subitem.title}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              className={getClassName(item.route)}
              onClick={() => history.push(item.route)}
            >
              {item.title}
            </Menu.Item>
          )
        ))}
      </Menu>
    </SiderCustom>
  );
}